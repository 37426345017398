import React from "react"
import GlobalNavi from "../../components/GlobalNavi"
import { Link } from "gatsby"
import DocLayout from "../../components/DocLayout"
import BpHr from "../../components/BpHr"
import { Box } from "@material-ui/core"
import SEO from "../../components/seo"

export default function Home(arg) {
  return (
    <div>
      <SEO title={`作家とナビゲーターのご紹介`} />
      <GlobalNavi />

      <div className="l-wrapper">
        <nav aria-label="パンくずリスト">
          <ul className={`breadcrumb`}>
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            <li>
              <Link to={`/profile`}>作家とナビゲーターのご紹介</Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className={`l-wrapper`}>
        <div className={`l-wrapper__inner`}>
          <DocLayout>
            <h2>作家とナビゲーターのご紹介</h2>
            <p>このプロジェクトに登場する作家とナビゲーターをご紹介します。</p>

            <Box
              mt={{ xs: `30px`, sm: `60px` }}
              mb={{ xs: `20px`, sm: `40px` }}
            >
              <h2 data-custom-type={`small`}>参加作家</h2>
            </Box>

            <Box>
              <h3>井口直人</h3>
              <p>
                1971年生まれ、三重県出身、名古屋市在住。1987年より「さふらん生活園」所属。街のコンビニと施設のコピー機を使って、自分の顔とその時々の気に入ったものを写し取ることを毎日の日課としている。ガラス面に顔を押し付け自分でボタンを操作し、センサー光の動きと共に身体を動かすことで、画面に独特の歪みを作り出す。このような行為は、支援員との遊びの中で生まれ、習慣化されたものである。コンビニには15年あまり通っており、終わると店員が手際よくガラス面についた顔の脂を拭いてくれる。
              </p>
            </Box>
            <BpHr type={`dot`} />
            <Box>
              <h3>鎌江一美</h3>
              <p>
                1966年生まれ、滋賀県在住。1985年から「やまなみ工房」に所属。思いを人に伝えるのが苦手な彼女は、コミュニケーションのツールとして振り向いて欲しい人の立体を作り続けている。モデルはすべて思いを寄せる男性。最初に題材を決め、原形を整えると、その表面全てを細かい米粒状の陶土を丹念に埋め込んでいく。完成までに大きな作品では約２か月以上を要する事もあり、無数の粒は作品全体を覆い尽くし様々な形に変化を遂げていく。大好きな人に認めてほしい。今もなお、その思いが彼女の創作に向かう全てである。
              </p>
            </Box>
            <BpHr type={`dot`} />
            <Box>
              <h3>川戸由紀</h3>
              <p>
                1984 年横浜市生まれ、横浜市在住。2003
                年より横浜市港北区のアート・メープルかれん
                に所属。ディズニーや子供番組のショーのキャラクター、舞台のほかにも風景や食べ物
                などが絵画や刺繍で表現される。それらは類似する物や構図で、連続して制作されるもの
                が多い。紙に描いた舞台、人形を動かしながらコマ撮りして、映像作品を制作していた時期もある。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>杉浦篤</h3>
              <p>
                1970年生まれ、埼玉県在住。社会福祉法人みぬま福祉会工房集所属。杉浦は暮らしの中でホッと一息つける夕食後や、のんびりと穏やかなひと時に、写真を入れた箱を抱え、時にはベッドに並べて寝転びながら楽しそうに写真を鑑賞する。写真たちは、何年も触り続けることで、様々な形になっていく。「すごいぞ、これは！」（埼玉県立近代美術館、2015年）、「Art
                Brut from Japan, Another Look」（Collection de l’art
                brut、2018年）をはじめ、展覧会参加多数。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>ジェス・トム</h3>
              <p>
                作家、アーティスト。自身のトゥレット症候群(チックの一種)を創造的に昇華するため、2010年にトゥレットヒーロー(Touretteshero)を共同設立。自伝的作品「Backstage
                in Biscuit
                Land」やサミュエル・ベケットの不条理演劇をもとにした「Not
                I」は、アンリミテッド・フェスティバルやエジンバラ・フリンジなどで上演され、高い評価を受ける。トゥレット症候群を持つ子ども向けの参加型イベントを主催するなど、コミュニティを育てる活動にも力を入れている。著書に『Welcome
                to Biscuit Land - A Year In the Life of
                Touretteshero』(2012年)。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>山本高之</h3>
              <p>
                1974年愛知県生まれ。小学校教諭としての経験から「教育」を中心テーマのひとつとし、子供のワークショップをベースに会話や遊びに潜む創造的な感性を通じて、普段は意識されることのない制度や慣習などの特殊性や、個人と社会の関係性を描く。近年は地域コミュニティと協働して実施するプロジェクトに多く取り組んでいる。主な展覧会に「Go
                Betweens:子どもを通して見る世界」(森美術館ほか2014-2015)、コチ=ムジリス・ビエンナーレ(インド
                2016)、Asian Art Award 2017(TERRADA ART COMPLEX 4F
                2017)など。近著に『芸術と労働』(共著、白川昌生+杉田敦編、水声社
                2018)。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>リ・ビンユアン</h3>
              <p>
                {" "}
                1985年中国永州生まれ。2011年中央美術学院彫刻科卒業。北京を拠点に活動。彫刻のマインドをもってパフォーマンス作品を制作、その多くを映像として発表。パフォーマンスという個人的な行為を通して、リは鑑賞者に物事を分ける境界を再考するよう促し、規制を媒介するものとしての常識に更なる疑問を投げかけている。最近の主な展覧会に「Land:
                Zhang Huan and Li Binyuan」（MoMA
                PS1、ニューヨーク、アメリカ、2018年）、「Li
                Binyuan」（HOW美術館、上海、中国、2019年）など。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>磯子区障害者地域活動ホーム + 飯塚聡</h3>
              <p>
                磯子区障害者地域活動ホームは横浜市磯子区にある、様々なハンディキャップを持った人達が集まるコミュニティ。近年の主な活動に、ヨコハマアートサイト事業「あいさつシリーズVol.2ぼくのあたりまえ　きみのあたりまえ」（https://y-artsite.org
                企画・映像協力：飯塚聡）がある。飯塚
                聡は映像作家。独立系映画製作会社勤務を経て、フリーランスのディレクターに。テレビ番組の演出を中心に幅広いジャンルの映像を制作。近年はドキュメンタリー映画の自主製作や、磯子区障害者地域活動ホームなどとの協働プロジェクトの企画・運営に取り組んでいる。バッカーズ(電撃障害者商品企画会議)の一員としても活動中。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>柏木麻里</h3>
              <p>
                詩人。ドイツ生まれ。第33回現代詩手帖賞受賞。近刊著書に
                日英語の詩集『蝶』（思潮社）、美術書『もっと知りたいやきもの』（東京美術）がある。そのほかの詩集に『蜜の根のひびくかぎりに』、『音楽、日の』。国際芸術センター青森、森岡書店などで詩の展示・朗読を行う。2019年まで出光美術館学芸員として陶磁展覧会を企画。ストルガ国際詩祭、プリンストン・フェステイバル招待参加など国内外で活動し、詩は数カ国語に翻訳されている。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>華雪</h3>
              <p>
                1975年、京都府生まれ。書家。立命館大学文学部哲学科心理学専攻卒業。92年より個
                展を中心に活動。文字の成り立ちを綿密にリサーチし、現代の事象との交錯を漢字一
                文字として表現する作品づくりに取り組むほか、〈文字を使った表現の可能性を探る
                〉ことを主題に、国内外でワークショップを開催する。刊行物に『ATO跡』（between
                the
                books）、『書の棲処』（赤々舎）など。作家活動の他に、『コレクション
                戦争
                ×文学』（集英社）、『石原愼太郎の文学』（文藝春秋）をはじめ書籍の題字なども
                多く手掛ける。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>金氏徹平</h3>
              <p>
                身のまわりの事物を素材に部分を切り抜き繋ぎ合わせることで、既存の文脈を読み替えるコラージュ的手法を用いて作品を制作。横浜美術館(2009年)、ユーレンス現代美術センター(北京
                2013年)、丸亀市猪熊弦一郎現代美術館(2016年)等で個展を開催、また、国内外の企
                画展・国際展で作品を発表している。2011年以降、舞台美術も複数手がけ、近年は舞台作品も制作している。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>dj sniff</h3>
              <p>
                ターンテーブル奏者、DJ、キュレーター、通訳・翻訳家。
                これまでにニューヨーク、アムステルダム、香港、東京などを拠点に活動。2014年から大友良英、ユエン・チーワイとともにアジアの実験的な音楽家たちを集めるアジアン・ミーティング・フェスティバル（AMF）のコ・ディレクターを務め、音楽家としてはターンテーブルと独自の演奏ツールを組み合わせながら演奏や作品制作をする。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>中川美枝子</h3>
              <p>
                埼玉県生まれ。2018年、津田塾大学国際関係学科卒業。2020年、東京外国語大学大学
                院博士前期課程修了。専門はドイツ語文学。2017年７月より、「視覚障害者とつくる
                美術鑑賞ワークショップ」のスタッフとして、「見ること」を切り口にしたワークシ
                ョップ活動を首都圏の美術館を中心に行っている。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>船越雅代</h3>
              <p>
                Pratt
                Instituteで彫刻を専攻後、料理に表現の可能性を見出す。NYの料理学校
                Institute of Culinary Education卒業。Blue Hill
                をはじめとするNYのレストランに勤めた後、ヨーロッパからアジアを放浪。オーストラリア船籍の客船のシェフとして大平洋を巡り、バリの老舗ホテルTandjung
                Sariのシェフを務め、2012年から拠点を京都に移し、国内外で、その土地を食文化、文化人類学、歴史などの視点から掘り下げ、食で表現する活動を展開する。2018年、京都にFarmoonをオープン。東アジア文化都市
                2016 奈良市 食部門ディレクター。Nara Food Caravan
                主宰。映画「空の器 An Empty
                Vessel」制作（監督：二宮宏央　撮影：印藤正人）。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>松本美枝子</h3>
              <p>
                写真家、アーティスト。写真や映像、文章などを媒介にした作品を制作。主な展示に水戸芸術館「クリテリオム68」（2006）、「茨城県北芸術祭」(2016)、中房総国際芸術祭「いちはらアート×ミックス」(2014)、ガーディアン・ガーデン「The
                Second Stage at
                GG#46」（2017）など。著書に写真詩集『生きる』（共著：谷川俊太郎、ナナロク社）など。「水戸のキワマリ荘」のほか、「メゾン・ケンポク」を運営し、研究とプロジェクトをベースにして、地域に場を開くことを実践している。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>横山裕一</h3>
              <p>
                1967年宮崎県生まれ。漫画家、美術家。武蔵野美術大学で油彩を学んだが、2000年以降、時間を描くことができる表現方法として漫画を選びとり、制作を始める。漫画作品に『ニュー土木』、『トラベル』、『NIWA』、『ベビーブーム』、『世界地図の間(ま)』、『アイスランド』、『プラザ』などがあり、その多くがフランス、アメリカ、イタリア、スペイン、ロシアなどの国で翻訳、出版されている。また絵画も並行して制作し、国内外で個展開催、グループ展に多数参加している。
              </p>
            </Box>

            <Box mt={`60px`} mb={`40px`}>
              <h2 data-custom-type={`small`}>おしゃべり対話鑑賞ナビゲーター</h2>
            </Box>

            <Box>
              <h3>ロバート キャンベル</h3>
              <p>
                日本文学研究者、国文学研究資料館長、東京大学名誉教授。ニューヨーク市出身。専門は江戸・明治時代の文学、特に江戸中期から明治の漢文学、芸術、思想などに関する研究を行う。「スッキリ」（日本テレビ系）や「Face
                to Face」（NHK
                国際放送）など、テレビでMCやニュース・コメンテーター等をつとめる一方、新聞雑誌連載、書評、ラジオ番組出演など、さまざまなメディアで活躍中。主な著書に『井上陽水英訳詞集』（講談社）、『ロバートキャンベルの小説家神髄
                現代作家６人との対話』（ NHK 出版）など。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>中川美枝子</h3>
              <p>
                埼玉県生まれ。2018年、津田塾大学国際関係学科卒業。2020年、東京外国語大学大学
                院博士前期課程修了。専門はドイツ語文学。2017年７月より、「視覚障害者とつくる
                美術鑑賞ワークショップ」のスタッフとして、「見ること」を切り口にしたワークシ
                ョップ活動を首都圏の美術館を中心に行っている。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>篠原ともえ</h3>
              <p>
                デザイナー／アーティスト。1995年歌手デビュー。文化女子大学（現・文化学園）短期大学部服装学科デザイン専攻卒。
                <br />
                映画、ドラマ、舞台など歌手・女優活動を経て、現在はイラストレーター、テキスタイルデザイナーなどさまざまな企業ブランドとコラボレーションするほか、衣装デザイナーとしても松任谷由実コンサートツアー、嵐ドームコンサートなどアーティストのステージ・ジャケット衣装を多数手がける。2020年、アートディレクター・池澤樹と共にクリエイティブスタジオ「STUDEO」を設立。7月に開催した「SHIKAKU
                -シカクい生地と絵から生まれた服たち-」では、サステナビリティと向き合い廃棄となる余剰の生地を余すことなく使い切る衣装作品などを展観した。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box>
              <h3>西岡克浩</h3>
              <p>
                美術と手話プロジェクト代表、株式会社丹青社所属。さまざまな人が集う文化施設などの空間づくりに携わりつつ、ユニバーサルミュージアム活動、ダイバーシティ関連研修の運営などを通じて、美術鑑賞を楽しむとともに「いろいろな人と、ともに体験し、ともに考えていく」ことを大切にしている。
              </p>
            </Box>
            <BpHr type={`dot`} />

            <Box mb={`100px`}>
              <h3>華雪</h3>
              <p>
                1975年、京都府生まれ。書家。立命館大学文学部哲学科心理学専攻卒業。92年より個
                展を中心に活動。文字の成り立ちを綿密にリサーチし、現代の事象との交錯を漢字一
                文字として表現する作品づくりに取り組むほか、〈文字を使った表現の可能性を探る
                〉ことを主題に、国内外でワークショップを開催する。刊行物に『ATO跡』（between
                the
                books）、『書の棲処』（赤々舎）など。作家活動の他に、『コレクション
                戦争
                ×文学』（集英社）、『石原愼太郎の文学』（文藝春秋）をはじめ書籍の題字なども
                多く手掛ける。
              </p>
            </Box>
          </DocLayout>
        </div>
      </div>
    </div>
  )
}
